.list_son {
    font-size: 12px;
    margin: 0 20px;
    margin-right: 0px;
    .team_lists {
        flex-wrap: wrap;
        height: 68px;
        //border: 1px solid #366FBB
        width: 240px;
        margin-bottom: 4px;
        box-sizing: border-box;
        padding: 4px 10px;
        border-radius: 4px;
        background: #8EBFEE;
        color: #fff;
        .limit {
            white-space: nowrap;
            max-width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bolder; } }
    .team_lists:last-child {
        margin-bottom: 0; }
    .team_list_li {
        margin-bottom: 5px; }
    .team_lists.over {
        background: #dddddd; } }
