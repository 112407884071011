.editor {
    position: relative;
    min-height: 300px;
    padding-bottom: 42px;
    .editor_bar {
        width: 50px;
        text-align: center;
        display: inline-block; }
    .opt-bar {
        position: sticky;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 10px 0;
        background: #fff; }

    .smallImg {
        vertical-align: middle;
        width: 100%;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .small_img {
            position: relative;
            width: 31%;
            margin-right: 5px; }
        img {
            width: 100%;
 }            //margin-right: 5px
        img:last-child {
            margin: 0; } }
    .upText {
        padding: 2px;
        margin-bottom: 2px;
        font-size: 14px;
        border: 1px dotted #000; }
    .upText_area {
        width: 100%;
        resize: none;
        border: 1px dotted #000;
        padding: 2px;
        height: auto; }
    .upText_area:focus {
        border: 1px dashed #423f3f !important;
        resize: none; }
    .editor_area {
        height: 40px;
        background: #ff0000;
        margin-bottom: 5px; }
    .bigimg {
        width: 100%;
        margin-bottom: 5px;
        position: relative;
        img {
            width: 100%; } }
    .text {
        position: relative; }
    .closeIcon {
        display: none;
        position: absolute;
        right: -10px;
        top: -10px;
        font-size: 20px;
        color: #366FBB;
        z-index: 9;
        cursor: default;
        background: #fff;
        cursor: pointer;
        border-radius: 50%;
        overflow: hidden; }
    .text:hover,.bigimg:hover,.small_img:hover {
        .closeIcon {
            display: block; } } }
.imageBox {
    display: inline;
    position: relative; }

.addimgicon {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border: 1px solid #ccc; }


