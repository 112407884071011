.printBox {
    position: relative;
    .tipsname {
        display: inline-block;
        min-width: 100px; }
    .selectDay {
        li {
            font-size: 12px;
            padding: 3px 13px;
            border-radius: 3px;
            color: #000;
            background: #fff;
            border: 1px solid #a3a1a1;
            margin-right: 5px;
            cursor: pointer; }
        li.active {
            background: #366fbb;
            color: #fff;
            border-color: #366fbb; } }
    .printtips {
        margin-top: 50px;
        margin-bottom: 5px;
        color: #706e6e; }
    .nextstep {
        margin-top: 5px;
        font-size: 12px;
        width: 80px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 4px;
        margin-right: 20px;
        //padding: 3px 10px
        background: #366fbb;
        color: #fff;
        display: inline-block;
        cursor: pointer; }
    a:link {
        color: #fff; }
    a {
        color: #fff; } }

