.home_header {
    width: 100%;
    //border: 1px solid #ccc
    align-items: flex-end !important;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 0;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 11;
    .searchBox {
        width: 1120px;
        height: 70px;
        background: #F8FAFD;
        margin-left: 20px;
        padding: 0 20px;
        border-radius: 4px;
        .search-btn {
            background: #366FBB;
            color: #fff;
            margin-right: 10px;
            border-radius: 4px; }
        .clear {
            font-size: 12px;
            color: #909090;
            cursor: pointer; } }
    .channel_name {
        width: 140px;
        height: 80px;
        border: 1px solid #8EBFEE;
        text-align: center;
        line-height: 80px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bolder;
        color: #366FBB;
        display: flex;
        align-items: center;
        justify-content: center; }
    .release_team {
        width: 220px;
        height: 70px;
        border: 1px solid #8EBFEE;
        text-align: center;
        line-height: 70px;
        //margin-bottom: 20px
        background: #366FBB;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
        font-size: 24px;
        font-weight: bolder;
        display: flex;
        align-items: center;
        justify-content: center; }

    .group_list {
        width: 240px;
        height: 80px;
        border: 1px solid #8EBFEE;
        text-align: center;
        //line-height: 80px
        position: relative;
        border-radius: 4px;
        margin: 0 20px;
        .title {
            position: absolute;
            top: 18%;
            font-size: 16px;
            width: 100%;
            font-weight: bolder;
            color: #366FBB;
            display: flex;
            align-items: center;
            justify-content: center; }

        .tab {
            height: 30px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            li {
                padding: 1px 6px;
                border: 1px solid #366FBB;
                cursor: pointer;
                height: 30px;
                box-sizing: border-box;
                flex: 1;
                border-right: 0;
                border-bottom: 0;
                line-height: 25px;
                font-size: 12px;
                color: #366FBB; }
            li:first-child {
                border-left: 0; }
            li.active {
                background: #366FBB;
                color: #fff;
                border: 1px solid #366FBB; } } }
    .caledar {
        width: 910px;
        height: 80px;
        //border: 1px solid #ccc
        overflow: hidden;
        overflow-x: auto; } }
