
.nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 10px -5px rgba(0,0,0,0.04);
    background: #366fbb;
    min-width: 1400px;
    .main {
        min-width: 1400px;
        display: flex;
        align-items: center;
        background: #366fbb;
        box-sizing: border-box;
        justify-content: space-between;

        .ant-menu-horizontal {
            height: 65px;
            line-height: 65px;
            background: transparent;
            border: 0;
            color: #fff; }
        .logo {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 220px;
            margin-right: 50px;
            img {
                width: 220px; }
            h1 {
                width: 220px;
                height: 48px;
                line-height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ffffff;
                border-radius: 50px;
                margin: 0; } }

        .navUl {
            padding: 0;
            height: 100%;
            list-style: none;
            display: flex;

            align-items: center;
            margin: 0;
            li {
                width: 100px;
                height: 100%;
                text-align: center;
                line-height: 65px;
                position: relative;
                // .active
                //     width: 80% !important
                //     border-bottom: 3px solid #FFFFFF
                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    color: #FFFFFF;
                    font-size: 14px;
                    text-decoration: none !important;
                    border-radius: 3px;
                    position: relative; }
                a.active::after {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    width: 80%;
                    height: 3px;
                    background: #ffffff;
                    left: 50%;
                    transform: translateX(-50%); } } } } }

.idChange {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    z-index: 99;
    .hide {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0,0 ,0 ,0.5 ); }
    .idBox {
        position: absolute;
        width: 520px;
        //min-height: 320px
        max-height: 500px;
        background: #fff;
        border-radius: 4px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        .title {
            text-align: center;
            padding: 20px;
            font-size: 24px;
            margin-bottom: 20px; }
        .idList {
            flex-wrap: wrap;
            max-height: 200px;
            overflow: hidden;
            overflow-y: auto;
            li {
                width: 25%;
                text-align: center;
                font-size: 16px;
                margin-bottom: 10px;
                cursor: pointer;
                padding: 10px 0;
                img {
                    box-sizing: border-box;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    overflow: hidden;
                    background: #ff0;
                    margin-bottom: 10px; } } }
        .contrlBtn {
            justify-content: center;
            //margin-top: 88px
            margin-bottom: 20px;
            li {
                width: 78px;
                height: 30px;
                line-height: 30px;
                background: #366FBB;
                font-size: 12px;
                text-align: center;
                color: #fff;
                border-radius: 4px; }
            li:first-child {
                //margin-right: 40px
                background: #9AB6DD; } }
        .idList::-webkit-scrollbar {
            width: 2px; }
        .idList::-webkit-scrollbar-thumb {
            background: #366fbb;
            border-radius: 0; }
        .idList::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 0; } } }

.topMenu {
    background: transparent; }

.ant-menu-item-selected {
    color: #fff !important; }

.ant-menu-item-selected:after {
    display: none; }

.ant-menu-overflow-item {
    padding: 0 30px !important; }

.select_active {
    background: #f7f4f4; }

.rc-virtual-list {
    .rc-virtual-list-holder-inner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: inherit !important;
        .ant-list-item-meta {
            display: block;
            text-align: center; }
        .ant-list-item {
            width: 25%;
            display: block;
            text-align: center;
            padding: 0;
            .ant-list-item-meta-avatar {
                margin: 0; }
            .ant-avatar-image {
                width: 60px;
                height: 60px; } } } }
.consignor_user_info {
    padding: 10px 0;
    .avatar-image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 10px; } }

.backMainIdentity {
    margin-left: 10px;
    color: #366FBB;
    cursor: pointer; }
.consignor_user_info {
    position: relative;
    cursor: pointer;
    .select_icon {
        display: none;
        position: absolute;
        left: 5px;
        top: 5px;
        color: #366FBB;
        font-size: 16px; } }
.consignor_user_info.select_active {
    cursor: not-allowed;
    .select_icon {
        display: block; } }


