.flex {
  display: flex;
  align-items: center; }
.orderDetail {
  margin: 0 20px;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto; }
.detailBox {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin: 20px 0;
  border-radius: 20px;
  .lists {
    margin-bottom: 20px; }
  .line {
    margin-bottom: 40px;
    .title {
      position: relative;
      color: #575757;
      font-size: 16px;
      font-weight: bold;
      padding-left: 14px;
      margin-bottom: 30px; }
    .title::after {
      position: absolute;
      content: '';
      height: 100%;
      left: 0;
      width: 4px;
      background: #366FBB; }
    .lineinfo {
      .copy {
        margin-left: 20px;
        color: #366FBB;
        cursor: pointer;
        width: auto; }
      .price {
        color: #FF7055; }
      li {
        line-height: initial;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 12px;
        color: #666666;

        p {
          width: 120px;
          text-align: right;
          margin: 0;
          color: #8C8C8C; }
        .icon {
          width: 20px;
          height: 20px; }
        .selfgetpic {
          width: 100px;
          height: 100px;
          margin-right: 20px;
          border-radius: 20px; } } }
    .total {
      float: right;
      overflow: hidden;
      margin: 40px 200px 100px 0; } } }


