.groupExamine {
    position: relative;
    padding: 20px;
    height: 800px;
    .tab {
        display: inline-flex;
        border-radius: 4px;
        overflow: hidden;
        li {
            background: #E9EFF7;
            padding: 7px 22px;
            font-size: 12px;
            cursor: pointer;
            color: #666666; }
        li.active {
            background: #366FBB;
            color: #fff; } }
    .rule {
        padding: 20px;
        //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
        background: #f8fafd;
        opacity: 1;
        border-radius: 4px;
        height: 80px;
        box-sizing: border-box;
        position: relative;
        margin: 20px 0; }
    .clear {
        cursor: pointer;
        width: 128px;
        height: 21px;
        font-size: 12px;
        font-weight: 400;
        line-height: 21px;
        color: #999999;
        opacity: 1;
        margin-right: 5px;
        margin-left: 10px; }
    .contrlBtn {
        .btn {
            cursor: pointer;
            margin-right: 10px; } }
    .agressWindow {
        text-align: center; }
    .refus {
        resize: none;
        margin-left: 20px;
        flex: 1; }
    .modalBtn {
        justify-content: center;
        margin-top: 20px;
        .btn {
            margin-right: 20px;
            background: #9AB6DD;
            font-size: 12px;
            color: #fff;
            padding: 7px 27px;
            border-radius: 4px;
            cursor: pointer; }
        .btn:last-child {
            background: #366FBB; } }
    .right_bottom {
        position: absolute;
        right: 10px;
        bottom: 10px; } }


