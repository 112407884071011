.list_li {
    width: 854px;
    //min-height: 80px
    //border: 1px solid #366FBB
    overflow: hidden;
    //overflow-x: auto
    .line_list {
        position: relative;
        //border: 1px solid #366FBB
        margin-bottom: 4px;
        box-sizing: border-box;
        //height: 66px
        //overflow: hidden
        display: flex;

        .tips_text {
            //position: absolute
            left: 0;
            top: 0;
            background: #fff;
            z-index: 10;
            padding: 0 10px;
            width: 59px;
            flex: 0 0 59px;
            box-sizing: border-box;
            color: #FF6666;
            height: 68px;
            p {
                height: 35px;
                //margin-bottom: 10px
                line-height: 35px;
                display: flex;
                align-items: center;
                justify-content: center; }
            p:last-child {
                margin-bottom: 0;
                color: #366FBB; } } }

    .list_li_line {
        height: 66px;
        //border: 1px solid #ccc
        margin-bottom: 10px; }
    .list_li_line:last-child {
        margin-bottom: 0; }
    .arrival_date,.end_datetime {
        //border: 1px solid #366FBB
        border-radius: 4px;
        //height: 35px
        box-sizing: border-box;
        //margin-bottom: 10px
        display: flex;
        position: relative;

        .lineLi {
            height: 32px;
            border: 0;
            text-align: center;
            line-height: 32px;
            padding: 0;
            position: relative;
            background: #F0F0F0;
            margin: 1px; }
        .lineLi.week {
            background: #dddddd; } }
    .arrival_date {
        .lineLi {
            background: #f6f6f6; } }

    .arrival_date {
        //border-top: 0
        border-bottom: 0; }
    .end_datetime {
        margin-bottom: 0;
        border-top: 0; }
    .dayLi {
        width: 100%;
        //overflow: hidden
        text-align: center;
        display: inline-block;
        font-size: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%); }

    .dayLi.success {
        color: #366FBB; }
    .dayLi.error {
        color: #FF6666;
        z-index: 9; } }
.circleLine {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 10; }

// .list_li::-webkit-scrollbar
//     height: 5px
//     width: 5px

// .list_li::-webkit-scrollbar-thumb
//     background-color: #366FBB
//     border-radius: 0px

// .list_li::-webkit-scrollbar-track
//     background-color: #fff
//     border-radius: 0px

