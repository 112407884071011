.merchant {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .qrCode {
        position: relative;
        margin-top: 20px;

        .tips {
            left: 0;
            position: relative;
            font-size: 20px;
            font-weight: bolder; } }
    .sign {
        .signCtx {
            width: 650px;
            margin: 0 auto;
            margin-top: 44px;
            .line {
                margin-bottom: 20px; }
            .label {
                width: 200px;
                display: inline-block;
                text-align: right; } }
        .agreeSign {
            text-align: center;
            margin-top: 270px; } }
    .examine {
        .title {
            font-size: 16px;
            font-weight: bolder;
            margin-bottom: 5px;
            color: #333; }
        .examine_tips {
            margin-left: 20px;
            font-size: 12px;
            color: #999999;
            position: relative;
            text-align: left;
            .tips {
                position: relative;
                left: 0; } }
        .examine_state {
            margin-bottom: 40px;
            font-size: 12px; } }
    .beginStep {
        .title {
            position: relative;
            font-size: 16px;
            font-weight: bolder;
            padding-left: 10px;
            border-left: 4px solid #366FBB; }
        .contents {
            margin-left: 10px;
            font-size: 12px; }
        .tipsLine {
            margin-left: 10px;
            margin-bottom: 20px;
            li {
                position: relative;
                padding-left: 10px;
                margin-bottom: 10px; }
            li::after {
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #366FBB;
                left: 0;
                position: absolute;
                top: 50%;
                margin-top: -4px; } }
        .href {
            color: #366FBB; } }
    .mainStay {
        padding: 0 5px;
        margin-bottom: 200px;
        li {
            width: 320px;
            box-sizing: border-box;
            height: 150px;
            padding: 20px;
            margin: 10px 0;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16);
            font-size: 12px;
            cursor: pointer;
            label {
                cursor: pointer; }
            .title {
                font-size: 16px;
                font-weight: bolder; }

            .spabet {
                justify-content: space-between; } }
        li:first-child {
            margin-right: 20px; }

        .treaty {
            margin-top: 30px;
            .treatyCtx {
                color: #366FBB;
                cursor: pointer; } } }
    .material {
        .title {
            margin-bottom: 20px; }
        .title:not(:first-child) {
            margin-top: 35px; }
        .inputTitle {
            width: 84px;
            text-align: right;
            margin-right: 20px; }
        .uploadsId {
            align-items: flex-start;
            margin-top: 20px; }
        .inputBox {
            margin-bottom: 10px;
            position: relative;
            .explain {
                color: #999999;
                b {
                    cursor: pointer; } } }
        .warn {
            color: #FF6666; }
        .uploads {
            button {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                opacity: 0; } }
        .identityPhoto {
                width: 150px;
                height: 90px;
                border: 1px dashed #7a7676;
                margin-right: 20px;
                line-height: 90px;
                text-align: center;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                .uploads {
                    z-index: 1;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%; }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    max-width: 100%;
                    max-height: 100%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%); }

                .photoTips {
                    margin-top: 35px; } } } }

.nextStep {
    padding: 0 40px;
    padding-bottom: 20px;
    justify-content: center;
    .stepCtrl {
        justify-content: center; }
    .prev {
        background: #9AB6DD;
        margin-right: 40px; } }
.themebtn {
    background: #366FBB;
    color: #fff; }
.marb20 {
    margin-bottom: 20px; }
.marb10 {
    margin-bottom: 10px; }
.themeColor {
    color: #366FBB; }

