.savePrint {
    margin-left: 40px;
    button {
        width: 80px;
        height: 30px;
        background: #366FBB;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        margin-right: 20px;
        border: 0; }
    .cancel {
        background: #96B3DC; } }
