p {
    margin: 0; }
.flex {
    display: flex;
    align-items: center; }
.grouping {
    margin: 20px;
    margin-right: 0;
    .box {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        padding: 20px;
        margin: 20px 0;
        border-radius: 4px; }
    .step1 {
        .box {
            max-width: 300px;
            .title {
                text-align: center;
                font-size: 16px;
                //font-family: Microsoft YaHei
                font-weight: bold;
                line-height: 26px;
                color: #000000; } } }

    .step1tips {
        max-width: 300px;
        padding: 24px 18px;
        background: #F0F0F0;
        color: #999999;
        font-size: 12px;
        border-radius: 4px;
        line-height: 16px;
        text-align: center; }
    .fenzhuRemarks {
        border: 1px solid #D9D9D9;
        opacity: 1;
        border-radius: 4px;
        overflow: hidden;
        .textinit {
            border: none;
            resize: none;
            textarea.ant-input {
                border: none;
                resize: none;
                outline: none; }
            textarea.ant-input:hover {
                border: none; }
            textarea.ant-input-textarea-show-count::after {
                padding: 0 10px; } } } }

.grouping {
    textarea:focus {
        border: none;
        box-shadow: none; } }
.ant-input:focus, .ant-input-focused {
    box-shadow: none; }
.button-bac.slow {
    background: #5E8BC8 !important; }
.add_fenzhu {
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #366FBB;
    width: 86px;
    height: 30px;
    background: unset;
    color: #366FBB; }

.step2 {
    align-items: flex-start !important;
    max-height: 700px;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    .stepLine {
        width: 10px;
        height: 520px;
        border-radius: 5px;
        background: #EAF0F8;
        position: relative;
        #contrl {
            width: 20px;
            height: 20px;
            background: #366FBB;
            border-radius: 50%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            cursor: grab; } }
    .cards {
        margin: 0 10px 0 10px;
        .card {
            position: relative;
            width: 240px;
            height: 100px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            opacity: 1;
            border-radius: 4px;
            padding: 20px;
            box-sizing: border-box;
            font-size: 16px;
            //font-family: Microsoft YaHei
            font-weight: 400;
            line-height: 21px;
            color: #000000;
            opacity: 1;
            margin-bottom: 10px;
            cursor: pointer;
            .linkcard {
                display: none;
                position: absolute;
                right: -27px;
                top: 8px;
                bottom: 0;
                padding: 10px 0;
                box-sizing: border-box;
                img {
                    width: 42px;
                    display: block; }
                img:first-child {
                    margin-bottom: 40px; } }
            p {
                line-height: 20px; }
            p:first-child {
                margin-bottom: 20px; }

            .title {
                align-items: center;
                justify-content: space-between; }

            .bot {
                margin-bottom: 20px;
                cursor: pointer;
                li {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #666666;
                    margin-right: 4px; } } }
        .card.active {
            background: #EAF0F8;
            .linkcard {
                display: block;
                z-index: 9; } }
        .card:last-child {
            margin-bottom: 0; } }

    .cardDetail {
        margin: 0;
        width: 600px;
        min-height: 700px;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        .addTeam {
            justify-content: space-between;
            margin-bottom: 10px;
            .btn {
                font-size: 12px;
                //font-family: Microsoft YaHei
                font-weight: 400;
                line-height: 21px;
                color: #FFFFFF;
                opacity: 1;
                padding: 7px 15px;
                background: #366FBB;
                border-radius: 4px;
                text-align: center;
                cursor: pointer; } }
        .title {
            font-size: 16px;
            //font-family: Microsoft YaHei
            font-weight: bold;
            line-height: 26px;
            color: #000000; }
        .pageContent {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            overflow: hidden;
            padding: 20px;
            .ant-pagination {
                float: right; } } }
    .teamContrl {
        p {
            cursor: pointer;
            margin-right: 10px; } } }
.write {
    input {
        width: 200px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        opacity: 1;
        border-radius: 4px; }
    label {
        white-space: nowrap;
        margin: 20px 0; } }

.fenzhuList {
    flex-wrap: wrap;
    li {
        font-size: 12px;
        //font-family: Microsoft YaHei
        font-weight: 400;
        line-height: 16px;
        color: #666666;
        opacity: 1;
        padding: 4px 20px;
        background: rgba(54, 111, 187, 0.1);
        opacity: 1;
        border-radius: 4px;
        margin: 5px 10px 5px 0;
        position: relative;
        .delSupplier {
            display: none;
            position: absolute;
            right: -5px;
            top: -5px; } }
    li:first-child {
        border-radius: 0;
        background: #ffffff;
        font-size: 12px;
        padding: 0;
        margin: 0; }
    li:hover {
        .delSupplier {
            display: inline-block; } }
    li.active {
        background: #40a9ff;
        color: #fff; } }

.confirmModal {
    width: 400px !important;
    font-size: 20px;
    .confirmTxt {
        font-size: 20px !important; } }
.addLabel {
    width: 400px !important; }
.addTeam {
    .searchTeam {
        width: 300px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        opacity: 1;
        border-radius: 4px;
        overflow: hidden;
        input {
            width: 430px;
            border: 0; }
        .searchBtn {
            width: 40px;
            height: 40px; } } }
.searchTeamBtn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    border-left: 1px solid #D9D9D9; }
.searchTeamLists {
    .searchRules {
        margin-bottom: 20px;
        .searchInput {
            width: 438px;
            height: 30px;
            line-height: 30px;
            border-radius: 4px;
            font-size: 12px; }
        button {
            width: 80px;
            border-radius: 4px;
            height: 30px;
            margin-left: 10px;
            background: #366fbb;
            font-size: 12px; } } }

.ant-table-cell {
    font-size: 12px; }

.add_teamer {
    max-height: 100%;
    overflow-y: auto; }
