.search_all {
    .addBtn {
        position: relative;
        width: 27px;
        height: 27px; }

    .search {
        margin-top: 10px;
        .channel_name {
            border: 1px solid #d9d9d9;
            padding: 5px 10px;
            text-align: left;
            width: 150px;
            cursor: pointer;
            background-color: #fff;
            font-size: 14px; } }
    .search.none {
        display: none; }
    .order_print {
        display: flex;
        border-radius: 4px;
        overflow: hidden;
        flex-wrap: wrap;
        li {
            padding: 7px 10px;
            background: #EAF0F8;
            font-size: 12px;
            cursor: pointer;
            width: 120px;
            box-sizing: border-box;
            text-align: center;
            border-right: 1px solid #fff;
            margin-bottom: 1px; }
        li.active {
            background: #366FBB;
            color: #fff; }
        li:last-child {
            border: 0; } }
    .marginR40 {
        flex-wrap: nowrap;
        white-space: nowrap;
        input {
            border-radius: 4px; } }
    .likeSearch {
        position: relative;
        width: 177px;
        height: 32px;
        .search_sel {
            position: absolute;
            left: 0;
            top: 32px;
            width: 100%;
            padding-bottom: 32px;
            background: #fff;
            border: 1px solid #ccc;
            border-top: 0;
            max-height: 150px;
            z-index: 9;
            overflow-y: auto;
            li {
                height: 32px;
                line-height: 32px;
                padding: 0 10px;
                border-bottom: 1px solid #e2e2e2;
                cursor: pointer; }
            li:hover {
                background: #366fbb;
                color: #fff; } } } }
.tipsModel {
    text-align: center;
    .title {
        font-size: 16px;
        padding: 20px 0;
        border-bottom: 1px solid #E5E5E5; }
    .tipstxt {
        padding: 40px 0; }
    .contrlBtn {
        button {
            width: 78;
            background: #366FBB;
            color: #fff;
            border-radius: 4px; }
        button:first-child {
            margin-right: 50px;
            background: #9AB6DD; } } }
.channel_show {
    .ant-list-item {
        border: 0;
        padding: 10px 0 !important; }
    li {

        cursor: pointer; }
    li:hover {
        background-color: #ccc; } }





