.upFileList {
    width: 104px;
    height: 104px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    border: 1px dashed #d9d9d9;
    vertical-align: top;
    margin: 0 8px 8px 0px;
    .imgList {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        max-width: 100%;
        max-height: 100%; }
    .contrl {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 9;
        display: none;
        .movePic {
            display: flex;
            align-items: center;
            color: #fff;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            position: absolute;
            justify-content: space-between; }
        .moveBtn {
            flex: 1;
            text-align: center;
            padding: 3px 0;
            cursor: pointer; }
        .center {

            position: relative;
            top: 50%;
            margin-top: -25%; }
        .btn {
            text-align: center;
            background: #fff;
            color: #000;
            cursor: pointer;
            padding: 5px 0;
            margin: 5px; }
        .btn.del {
            background: #ff0000;
            color: #fff; } }
    .selectImg {
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        z-index: 9; }
    .selectImg.active {
        display: block; } }
.upFileList:hover {
    .contrl {
        display: block; } }
