p {
    margin: 0; }
.flex {
    display: flex;
    align-items: center; }
.tproducts {
    margin: 20px;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    .box {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        padding: 20px;
        margin: 20px 0;
        border-radius: 10px; }
    .contrl {
        li {
            font-size: 16px;
            //font-family: Microsoft YaHei
            font-weight: 400;
            line-height: 21px;
            color: #909090;
            opacity: 1;
            margin-right: 20px;
            cursor: pointer; } }
    .tp_desc {
        width: 400px; }
    .tp_contrl {}
    .tp_name {
        width: 300px; }
    .tp_creat_time {
        width: 200px; }
    .pageContrl {
        position: absolute;
        right: 40px;
        bottom: 40px; } }
