p {
    margin: 0;
    line-height: initial; }
.flex {
    display: flex;
    align-items: center; }
.detailed {
    margin: 20px;
    align-items: flex-start;
    justify-content: space-between;
    .box {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        padding: 20px;
        border-radius: 4px;
        box-sizing: border-box; }
    .boxLeft {
        width: 440px;
        box-sizing: border-box;
        height: 470px; }
    .boxRight {
        width: 740px;
        box-sizing: border-box; }
    .title {
        height: 30px;
        padding-left: 20px;
        line-height: 30px;
        color: #666666;
        font-size: 16px;
        font-weight: bolder;
        position: relative; }
    .title::after {
        width: 4px;
        height: 30px;
        position: absolute;
        background: #366FBB;
        left: 0;
        top: 0;
        content: ''; }
    .title::before {
        width: 100%;
        height: 2px;
        bottom: -10px;
        left: 0;
        position: absolute;
        background: #DFDFDF;
        content: ''; }
    .citySelect {
        input {
            width: unset; } }
    .selectDate {
        input {
            width: 300px; } }
    .citySelect label {
        min-width: 80px; }
    .writeInput {
        input {
            width: 110px; } }
    .button-bac {
        margin: 0 10px; }
    .wealth {
        justify-content: space-between;
        margin-bottom: 30px;
        li {
            padding: 0 10px;
            box-sizing: border-box;
            text-align: center;
            width: calc(23%);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            div {
                color: #366FBB;
                font-size: 20px;
                padding: 14px 0;
 }                //font-weight: bolder
            div:first-child {
                font-size: 16px;
                color: #606060;
                border-bottom: 1px solid #E5E5E5; } } } }
