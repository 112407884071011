.progress {
    li {
        margin-right: 20px;
        cursor: pointer; }
    li:nth-child(1) {
        width: 370px; }
    li:nth-child(2) {
        width: 330px; }
    margin: 40px;
    .circular {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #ccc;
        color: #ccc;
        text-align: center;
        line-height: 30px;
        font-size: 16px;
        font-weight: bolder;
        margin-right: 10px; }
    .afterLine {
        position: relative; }
    .afterLine::after {
        content: '';
        position: absolute;
        width: 200px;
        height: 1px;
        border-bottom: 1px dashed #ccc;
        right: 0;
        top: 50%; }
    li.active {
        .circular {
            background: #366FBB;
            color: #fff;
            border: 1px solid #366FBB; } } }




