p {
    margin: 0; }
.flex {
    display: flex;
    align-items: center; }
.logisticsRoute {
    margin: 0 20px;
    .box {
        //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
        background: #f8fafd;
        padding: 20px;
        margin: 20px 0;
        border-radius: 4px; }
    .top {
        label {
            font-size: 16px;
            color: #8C8C8C; }
        input {
            height: 30px;
            line-height: 30px;
            border-radius: 4px; }
        .searchBtn {
            width: 80px;
            height: 30px;
            border-radius: 4px;
            background: #366FBB;
            font-size: 12px;
            margin-left: 20px; } }
    .getSelf {}
    .primaryBtn {
            height: 30px;
            border-radius: 4px;
            background: #366FBB;
            font-size: 13px;
            margin-left: 20px; }
    .contrlTxt {
        color: #909090;
        font-size: 16px; }
    p {
     margin: 0;
     margin-right: 20px;
     cursor: pointer; } }
.editorBox {}
.BMap {
    overflow: hidden;
    width: 460px;
    height: 160px; }
.citySelect {
    margin: 10px 0;
    font-size: 16px;
    text-align: left;
    color: #666666;
    label {
        white-space: nowrap; } }
.teamInfo {
    img {
        width: 50px;
        height: 50px;
        border-radius: 10px; }
    .teamInfoText {
        font-size: 12px;
        color: #000;
        padding-left: 10px;
        line-height: 34px; } }
.total {
    .headLine {
        width: 290px;
        height: 10px;
        background: #5E8BC8;
        border-radius: 40px; }
    ul {
        border-radius: 0px 0px 10px 10px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        li:last-child {
            background: #ffffff;
            p {
             font-size: 12px; } }
        li {
            font-size: 16px;
            background: #EAF0F8;
            p {
                width: calc(50%);
                font-size: 16px;
                text-align: center;
                padding: 9px 0; } } } }



