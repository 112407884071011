.bornList {
    width: 450px;
    overflow: hidden;
    overflow-y: auto;
    max-height: 100%;
    .nodata {
        text-align: center;
        margin-top: 30px; } }
.marginB10 {
    margin-bottom: 10px; }
.detail-header {
    padding: 20px;
    padding-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin: 5px;
    .headerTop {
        align-items: flex-start;
        position: relative;
        padding-bottom: 20px; }
    .headerTop::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background: #E5E5E5;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%); }

    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        margin-right: 10px;
        flex: 0 0 50px; }
    .userInfo {
        font-size: 12px;
        position: relative; }
    .pstime {
        flex-wrap: wrap;
        li {
            padding: 4px 11px;
            color: #366FBB;
            border: 1px solid #366FBB;
            border-radius: 4px;
            margin-right: 10px;
            margin-bottom: 10px; } }
    .headerBottom {
      justify-content: center;
      padding-top: 10px;
      .showNum {
         border: 1px solid #d9d9d9;
         border-radius: 4px;
         padding: 10px 40px;
         font-size: 12px;
         text-align: center;
         margin-right: 20px;
         p {
            color: #366FBB;
            font-weight: bold;
            font-size: 16px; } } } }

.txtColor {
    color: #366FBB; }
.transList {
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    margin: 3px;
    margin-top: 10px;
    .listLeft {
        border-radius: 4px 0 0 4px;
        width: 80px;
        color: #fff;
        font-size: 12px;
        background: #366FBB;
        height: 60px;
        line-height: 60px;
        text-align: center;
        box-sizing: border-box;
        padding-top: 14px;
        overflow: hidden; }
    .listLeft {}
    .listRight {
        height: 60px;
        flex: 1;
        padding: 10px 20px 10px 10px;
        font-size: 12px;
        justify-content: space-between;
        align-items: flex-start;
        .endTime {
            color: #999999;
            padding-top: 10px; } }
    .handleOpen {
        color: #366FBB;
        border-radius: 4px;
        border: 1px solid #366FBB;
        padding: 1px 5px;
        float: right;
        margin-top: 4px;
        cursor: pointer; } }
.transList {
    ::before {
        position: absolute;
        width: 12px;
        height: 42px;
        content: '';
        left: 10px;
        top: -24px;
        //background: url('../../../../../assets/line.png') no-repeat
        background-size: 12px; }
    ::after {
        position: absolute;
        width: 12px;
        height: 42px;
        content: '';
        right: 10px;
        top: -24px;
        //background: url('../../../../../assets/line.png') no-repeat
        background-size: 12px; } }


