.font16 {
  font-size: 16px; }
.refund_reason {
  margin-top: 40px;
  p {
    font-size: 12px;
    //line-height: 25px
    margin-bottom: 10px; } }
.flex {
  display: flex;
  align-items: center; }
.orderManagement {
  //width: 100%
  margin: 20px 20px 0 20px;
  max-width: 1320px;
  .ant-table-tbody > tr > td {
    padding: 5px 1px!important;
    overflow: hidden; }
  .outstyle.detail {
    cursor: pointer;
    color: #366fbb; }

  .outstyle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
    display: block;
    .ctxp {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } }
  .list {
    height: 470px;
    overflow-y: auto; }
  .flex {
    display: flex; }
  .orderLi {
    font-size: 16px;
    line-height: initial;
    margin-left: 20px;
    li.active {
      background: #366FBB; }
    li {
      border-radius: 5px;
      padding: 6px 17px;
      background: #5E8BC8;
      color: #ffffff;
      margin-right: 10px;
      cursor: pointer;
      font-size: 12px;

      .needMargin {
        margin-bottom: 20px; }
      .search-left {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        padding: 20px;
        border-radius: 20px;
        .input-radius {
          border-radius: 20px;
          width: 300px;
          .getOrderNum {
            width: 360px !important;
            .clear {
              cursor: pointer;
              width: 128px;
              height: 21px;
              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              color: #999999;
              opacity: 1;
              margin-right: 5px;
              .search-btn {
                width: 102px !important;
                height: 40px !important;
                background: #366FBB !important;
                opacity: 1 !important;
                border-radius: 20px !important; } } } } }
      .issue {
        margin-left: 20px;
        height: 140px;
        background: #366FBB;
        opacity: 1;
        border-radius: 20px;
        box-sizing: border-box;
        i {
          width: 65px;
          height: 60px;
          line-height: 60px;
          display: inline-block;
          vertical-align: middle;
          background: url("../../../assets/click.png") no-repeat; } } } } }

.grouptype {
  width: 140px;
  overflow: hidden;
  height: 120px;
  background: #366FBB;
  opacity: 1;
  border-radius: 20px;
  color: #fff;
  vertical-align: middle;
  margin: 0 5px;
  box-sizing: border-box;
  h2 {
    color: #fff; }
  .span-wrap {
    overflow: hidden !important;
    white-space: pre-wrap !important;
    word-break: break-all !important; } }

.clearParam {
  cursor: pointer;
  color: #999999;
  font-size: 14px; }

.searchBtn {
  width: 80px;
  height: 30px;
  border-radius: 4px;
  background: #366FBB;
  font-size: 14px;
  padding: 0; }

.ruleTab {
  overflow: hidden;
  width: max-content;
  margin: 20px 0 10px 0;
  justify-content: space-between;
  border-radius: 4px;

  li {
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    //padding: 9px 38px
    font-size: 12px;
    color: #666666;
    background: #EAF0F8;
    cursor: pointer;
    border-right: 1px solid #fff; }
  li:last-child {
    border: 0; }
  li.active {
    background: #366FBB;
    color: #ffffff; } }

ul.contrl {
  margin: 20px 0 10px 0;
  li {
    text-align: center;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    background: #366FBB;
    font-size: 12px;
    color: #fff;
    //padding: 3px 15px
    margin: 0 10px;
    border-radius: 3px;
    cursor: pointer; } }

.lists {
  .ant-table-thead {
    background: #F5F7FA;
    tr {
      background: #366FBB; } }
  .gary {
    background: #F5F5F5; } }

.contrlBtn {
  color: #909090;
  font-size: 16px;
  flex-wrap: wrap;
  li {
    width: calc(100%/2);
    cursor: pointer;
    font-size: 12px; } }

.ant-table-thead tr .ant-table-cell {
  background: #F5F7FA !important;
  font-weight: bold; }

.ant-table-thead tr .ant-table-cell {
  color: #666666 !important; }

.orderManagement {
  .needMargin {
    margin: 20px 0; }
  .search-left {
    width: 100%; } }

.sendGoods {
  .line {
    margin: 20px 0;
    p {
      margin-bottom: 10px;
      label {
        display: inline-block;
        margin-bottom: 5px; } } } }

.marginR40 {
  margin-right: 40px; }

.refundcancelBtn {
  background: #9ab6dd !important; }

.areaGoods {
  border-bottom: 1px solid #f0f0f0; }

.shop_detail {
  max-height: 150px;
  overflow: hidden;
  overflow-y: auto; }
.shop_detail::-webkit-scrollbar {
  width: 2px; }

.shop_detail::-webkit-scrollbar-thumb {
  background: #366fbb;
  border-radius: 0; }

.shop_detail::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 0; }
