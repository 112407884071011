.ladder {
      height: 140px;
      background: #F0F8FF;
      border-radius: 20px;
      text-align: left;
      padding-left: 20px;
      margin-top: 10px;
      td {
         font-size: 12px; } }
.add {
   margin-top: 20px;
   border-radius: 10px;
   width: 450px;
   border: 1px solid #366FBB;
   color: #366FBB; }
.group-content {
   margin: 10px 20px;
   .fotter {
      padding: 20px 0;
      text-align: center;
      .issue {
            margin-right: 40px;
            height: 70px;
            width: 200px;
            background: #366FBB;
            opacity: 1;
            border-radius: 4px;
            box-sizing: border-box;
            i {
                display: inline-block;
                vertical-align: middle; }
            span {
              font-size: 24px; }
            .save {
               width: 32px;
               height: 32px;
               line-height: 32px;
               background: url("../../../assets/save.png") no-repeat;
               background-size: 25px; }
            .send {
               width: 32px;
               height: 32px;
               line-height: 32px;
               background: url("../../../assets/click.png") no-repeat;
               background-size: 25px; } } }
   .modal {
     display: flex;
     box-sizing {}
     .modal-con {
       display: inline-block;
       flex: 1;
       //margin: 0 5px
       //padding: 0 10px
       //min-height: 700px
       //max-height: 700px
       background: #FFFFFF;
       //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
       opacity: 1;
       overflow: hidden;
       overflow-y: auto;
       .input-style {
            border-radius: 4px;
 }            //margin-top: 10px
       .title {
          font-size: 16px;
          //font-family: Microsoft YaHei
          font-weight: bold;
          line-height: 26px;
          opacity: 1;
          margin: 10px 0 40px 0;
          text-align: center; }
       .conditionDate {
          width: 190px;
          height: 40px;
          border-radius: 4px;
          margin: 10px 10px 20px 0; }
       .condition {
            width: 50px !important;
            border: none !important;
            border-bottom: 1px solid #333 !important;
            border-radius: 0; }
       .list-bg {
           background: #f6f6f6;
           padding: 10px;
           margin: 10px 0;
           border-radius: 4px;
           position: relative;
           font-size: 12px;
           .inco-sty {
               font-size: 25px;
               position: absolute;
               top: -10px;
               right: -5px;
               color: #ccc; }
           li {
               margin-bottom: 20px;
               .input-style {
                     width: 120px;
                     height: 30px;
                     background: #FFFFFF;
                     border: 1px solid #D9D9D9;
                     opacity: 1;
                     border-radius: 4px; }
               .title-lab {
                     font-size: 12px;
                     font-weight: 400;
                     color: #8C8C8C; } } }

       .sty-left {
         vertical-align: top;
         //max-width: 90px
         display: inline-block; }
       .search-btn {
            width: 78px !important;
            height: 30px !important;
            background: #366FBB !important;
            opacity: 1 !important;
            border-radius: 4px !important;
            font-size: 12px;
            font-width: 400;
            margin-right: 10px; } } }
   .addGoods {
      border: 1px solid #bbb9b9;
      border-radius: 4px;
      padding: 5px;
      text-align: center;
      font-size: 12px;
      color: #706d6d;
      justify-content: center;
      margin: 10px 0;
      cursor: pointer; }
   .ant-empty-normal {
      margin: 0; } }
.goods_Editor {
   border: 1px solid #e4dbdb;
   padding: 10px 10px 0 10px;
   position: relative;
   min-height: 300px;
   max-height: 450px;
   overflow-y: auto;
   .contrl {
      justify-content: space-between;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      background: #fff;
      padding: 10px; }
   .bigimg {
      display: block; }
   .ant-upload-list {
      display: none; } }

.bronTeam {
   input {
      border: 0;
      border-bottom: 1px solid #5f5d5d;
      border-radius: 0; } }

.newGroupBtn {
   justify-content: center;
   margin: 30px 0;
   .contrlbtn {
      width: 78px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-size: 12px;
      border-radius: 4px;
      background: #5E8BC8;
      color: #fff;
      margin-right: 40px;
      cursor: pointer; }
   .contrlbtn:last-child {
      background: #366FBB; } }

.onlySee {
   text-align: center;
   font-weight: bolder;
   font-size: 16px;
   padding: 20px 0;
   border-top: 1px dashed #ccc; }

.openTeamKnow {
   .textarea {
      display: block;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      resize: none;
      box-sizing: border-box;
      padding: 10px;
      margin-top: 10px; } }

.movePosition {
   overflow: hidden;
   justify-content: space-between;
   margin-bottom: 5px;
   .goods_Number {
      width: 25px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      border: 1px solid #366FBB;
      color: #ffffff;
      background-color: #366FBB; }
   .btn {
      border: 1px solid #366FBB;
      border-radius: 2px;
      padding: 1px 5px;
      cursor: pointer;
      margin-left: 10px; } }

.fixArrTable {
   text-align: center;
   border: none;
   color: #8c8c8c;
   .writeBox {
      padding: 0;
      text-align: center;
      width: 50px; } }
.pointer {
   cursor: pointer; }

.add_arrival_time {
   width: 100%;
   border-collapse: collapse;
   margin: 0 auto;
   text-align: center;
   td,th {
      border: 1px solid #cad9ea;
      color: #666;
      height: 30px; } }

.pointer {
   cursor: pointer; }

.can_see_circle {
   .ant-table-tbody > tr > td {
      height: 40px; } }

.can_see_circle {
   .select_circle_name {
      margin-bottom: 10px;
      justify-content: space-between; } }
