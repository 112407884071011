.Newgoods {
   margin: 20px;
   height: 100%;
   overflow: hidden;
   overflow-y: auto;
   .addSpecval {
     color: #cccccc;
     margin-top: 10px; }
   .conton {
     overflow: auto;
     //margin-top: 20px
     padding:  10px 20px;
     width: 100%;
     //max-height: 800px
     box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
     opacity: 1;
     border-radius: 7px;
     .save {
          background: #366FBB;
          color: #fff;
          opacity: 1;
          border-radius: 4px;
          margin-right: 40px;
          width: 78px;
          height: 30px;
          font-size: 12px; }

     .needMargin {
       margin-left: 44px;
       font-size: 12px;
       color: #8C8C8C;
       margin-bottom: 15px;
       position: relative;
       padding-bottom: 15px;
       label {
         //min-width: 120px
         display: inline-block; } }
     .needInput {
         border-radius: 4px;
         width: 250px;
         margin-left: 15px;
         margin-right: 10px;
         font-size: 12px; }
     .needSelect {
         border-radius: 7px;
         width: 250px;
         margin-left: 15px; }
     .new-specif {
         width: 104px;
         height: 40px;
         background: #5E8BC8;
         opacity: 1;
         border-radius: 20px;
         color: #fff; }
     .goods-title {
        width: 149px;
        height: 26px;
        font-size: 15px;
        //font-family: Microsoft YaHei
        font-weight: 400;
        line-height: 26px;
        color: #575757;
        letter-spacing: 1px;
        opacity: 1;
        margin: 0;
        .creat-conton {
          position: relative;
          margin 0 {} } } } }
.ant-upload-picture-card-wrapper {
  width: unset;
 }  //margin-left: 15px
.ant-upload-list-picture-card-container {
  display: none; }
.mainUpGoods {
  h3 {
    font-size: 15px !important;
    //font-family: Microsoft YaHei
    font-weight: bold;
    line-height: 26px;
    color: #575757;
    opacity: 1;
    margin-bottom: 20px;
    span {
      margin-right: 10px;
      height: 20px !important;
      line-height: 20px !important; } } }
.specsBox {
  border: 1px solid #b3aeae;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0;
  position: relative;
  .goCenter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); }
  label {
    width: unset !important;
    min-width: unset !important; }
  .line {
    margin: 10px 0; }
  .addSpecsNameVal {
    color: #1e6ce0;
    cursor: pointer; } }
.openSpecs {
  label {
    min-width: 100px; }
  .ant-switch {
    margin-left: 15px; } }
.addSpces {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative; }
.brithInput {
  position: relative;
  .close {
      position: absolute;
      right: -5px;
      top: -5px;
      font-size: 20px;
      background: #ffffff;
      z-index: 9;
      cursor: pointer; } }


.ant-divider-horizontal {
  margin-top: 10px;
  border-color: rgb(234,230,230) !important; }


