.reduce {
    .font12 {
        font-size: 12px; }
    .flex {
        display: flex; }
    .contrl {
        justify-content: space-between;
        margin-bottom: 5px; }
    .discount {
        margin-top: 10px; }
    .table {
        th,tr,td {
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 95px;
            overflow: hidden; }
        font-size: 12px;
        input {
            width: 50px; }
        .tableBox {
            width: 100%;
            border: 1px solid #ccc;
            tr {
                border: 1px solid #ccc;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
            .delBtn {
                cursor: pointer; } } } }
