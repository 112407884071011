.orderReport {
    margin: 20px;
    .toptips {
        font-size: 12px;
        background: #f0f0f0;
        border-radius: 4px;
        text-align: center;
        line-height: 30px;
        color: #999999; }
    .overTable {
        font-size: 12px;
        color: #909090;
        margin: 20px 0 20px 0;
        b {
            color: #666666;
            font-size: 14px; } }
    .updateOrderList {
        padding: 2px 10px;
        display: inline-block;
        border: 1px solid #1890ff;
        color: #1890ff;
        border-radius: 1px;
        font-size: 12px;
        cursor: pointer; } }
