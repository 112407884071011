.wx_ctx {
    p {
        margin-top: 5px;
        text-align: center;
        font-size: 16px;
        color: #fff; } }
#wx_login_container {
    position: relative;
    width: 200px;
    height: 200px;
    text-align: center;
    overflow: hidden;
    background: #fff;
    margin-bottom: 20px;
    z-index: 2;
    background-image: url('../../../assets/waitting.gif');
    background-size: 50px;
    background-position: center;
    background-repeat: no-repeat;
    iframe {
        width: 100%;
        height: 100%; } }

#fwhfCanvas {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none; }

.impowerBox .qrcode {
    width: 200px !important; }
