.swip {
    cursor: grab; }
.home_main_caledar {
    position: relative;
    width: 876px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
.home_ul_son {
    display: flex;
    width: 100%;
    //justify-content: space-between
    li {
        //padding: 0 12px
        font-size: 12px;
        box-sizing: border-box;
        width: 32px;
        margin: 0 1px;
        position: relative; }
    li:hover {
        .nongli {
            display: block; } } }
.nongli {
    position: absolute;
    width: 80px;
    min-height: 35px;
    background: rgba(54 ,111 ,187, 0.5);
    border-radius: 10px;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    .content {
        color: #fff;
        font-size: 12px;
        position: relative; }
    .content::after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid rgba(54 ,111 ,187, 0.5);
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%); } }
.nongli_show {
    .nongli {
        display: block; } }
.nongli.show {
    display: block; }
