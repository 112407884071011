.system {
    margin: 0 20px;
    .top_tips {
        margin-top: 20px;
        margin-bottom: 40px;
        p {
            line-height: 30px;
            font-size: 16px;
            color: #FF6666; } }
    .set_switch {
        flex-wrap: wrap;
        display: flex;
        li {
            width: 360px;
            height: 100px;
            border-radius: 4px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            padding: 30px;
            box-sizing: border-box;
            justify-content: space-between;
            margin-left: 20px;
            margin-bottom: 20px; }
        img {
            max-width: 40px;
            max-height: 40px; } } }
