.receipt {
    .bold {
        font-weight: bold; }
    .bolder {
        font-weight: bolder; }
    .font24 {
        font-size: 24px; }
    .set_title {
        margin-bottom: 10px; }
    .poll {
        //overflow-y: auto
        .center {
            height: auto; }
        .listBox {
            flex: 0 0 310px;
            height: 100%;
            overflow-y: auto; }
        .left {
            flex: 0 0 310px;
            background: #fff;
            //padding: 0
            //margin: 15px 20px
            border: none;
            position: relative;
            height: 100%;
            overflow-y: auto; }
        .pagebreak {
            padding: 0px; }
        .right {
            flex: 1; }
        .print_state {
            display: inline-block;
            background: #f0f0f0;
            padding: 2px 10px;
            position: absolute;
            left: 0;
            top: 0; }
        .shops_table {
            width: 100%;
            .bold {
                font-weight: bold; } }
        .margin10_0 {
            margin: 10px 0; } }
    .marb10 {
        margin-bottom: 10px; }
    .big_user {
        font-size: 30px;
        font-weight: bold; } }
