.sider {
      width: 160px !important;
      margin: 0;
      max-width: 160px !important;
      min-width: 100px !important;
      background: #fff !important;
      box-shadow: 1px 1px 1px  #f3f0f0;
      .logo {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 1;
            h2 {
                width: 32px;
                height: 40px;
                margin: 60px 64px 51px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                background: url("../assets/book.png") no-repeat; } }

      ul {
       margin: 0;
       padding: 0;
       li {
        list-style: none;
        color: #366FBB;
        .active {
          background: #EAF0F8; }
        a {
          display: inline-block;
          color: #366FBB;
          font-size: 12px;
          text-decoration:none !important {}
          width: 100%;
          text-align: center;
          font-family: 'pingfang',Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
          .anticon {
 } } } } }			        // color: #4f9863!important
.ant-menu-item-selected {
 position: relative;
	a {

		.anticon {
			color: #366FBB!important; }
		span {
			color: #366FBB!important; } }
	&:after {
		content: "";
		border-right: none!important; }
 &:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-bottom: 2px solid #fff; } }


.left_icon {
  margin-right: 10px;
  padding: 5px 0; }

.leftMenu {
  .ant-menu-item {
    height: 50px;
    line-height: 50px;
    margin: 0 !important;
    padding: 0 44px !important; } }

.main_user_name {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }







