.login {
    width: 100vw;
    height: 100vh;
    z-index: 1;
    //background: linear-gradient(359deg, #366FBB 0%, #1B385E 100%)
    opacity: 1;
    position: relative;
    .content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        padding: 20px;
        width: 800px;
        .ctx {
            align-items: flex-start;
            justify-content: space-between;
            .wx_ctx {
                p {
                    margin-top: 5px;
                    text-align: center;
                    font-size: 16px;
                    color: #fff; } } }
        .brand {
            font-size: 28px;
            color: #fff;
            margin-top: 150px; } } }
#wx_login_container {
    position: relative;
    width: 200px;
    height: 200px;
    text-align: center;
    overflow: hidden;
    background: #fff;
    margin-bottom: 20px;
    z-index: 2;
    background-image: url('../../../assets/waitting.gif');
    background-size: 50px;
    background-position: center;
    background-repeat: no-repeat;
    iframe {
        width: 100%;
        height: 100%; } }
#fwhfCanvas {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none; }

.impowerBox .qrcode {
    width: 200px !important; }

.logo_txt {
    margin-bottom: 20px !important;
    margin-top: 10px !important;
    font-size: 26px !important;
    font-weight: bolder !important; }

.waitimg {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); }

