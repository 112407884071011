p {
    margin: 0;
    line-height: initial; }
.flex {
    display: flex;
    align-items: center; }
.probably {

    padding: 0 20px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .boxLeft {
        width: 840px;
        box-sizing: border-box; }
    .boxRight {
        width: 340px;
        box-sizing: border-box; }
    .box {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        padding: 20px;
        margin-top: 20px;
        border-radius: 4px; }
    .title {
        color: #666666;
        font-size: 16px;
        font-weight: 400;
        margin: 20px 0 10px 0; }

    a {
        font-size: 12px;
        color: #999999; }
    .donot_support {
        border-radius: 4px;
        font-size: 12px;
        color: #999999;
        background: #F0F0F0;
        text-align: center;
        padding: 9px 0; } }
.top {
    label {
        font-size: 12px;
        color: #8C8C8C; }
    input {
        height: 40px;
        line-height: 40px;
        border-radius: 20px; }
    .searchBtn {
        height: 40px;
        border-radius: 20px;
        background: #366FBB;
        font-size: 16px;
        margin-left: 20px; }
    .tips {
        color: #909090;
        font-size: 12px;
        margin-top: 10px;
        position: relative;
        left: 0; } }

.bold_font {
    color: #366FBB;
    font-size: 32px;
    font-weight: 600;
    margin-right: 20px; }

.contrlBtn {
    margin-bottom: 10px;
    .btns {
        margin-right: 10px;
        background: #5E8BC8;
        color: #ffffff;
        font-size: 12px;
        border-radius: 4px;
        padding: 7px 15px;
        cursor: pointer; }
    .btns.active {
        background: #366FBB; } }
.state1 {
    color: #366FBB; }
.state0 {
    color: #eb2323; }

.warnTips {
    overflow: hidden;
    overflow-y: auto;
    height: 288px;
    font-size: 12px;
    .title {
        color: #999999;
        font-size: 12px;
        margin: 0;
        margin-bottom: 10px; }
    .lists {
        li {
         color: #999999; } } }
