.demo .next-btn {
    margin-right: 5px;
}
//规格部分
.taglist{
    $interval:10px;
    background: #f7f7f7;
    margin-bottom: $interval;
    .tagtitle{
        background: #EBECF0;
        padding:  $interval;
    }
    .tagcustom{
        padding: $interval;
        .custom{
            border: 1px dashed;
            display: inline-block;
            .next-tag{
                margin: $interval/2;
            }
            .next-input{
                max-width: 150px;
            }
            .next-input,button{
                border-radius: 0 !important;
            }
        }
    }
}
//表格部分处理
.next-table{
    tr{
        th,td{
            padding: 5px;
            text-align: center;
            .next-input{
                max-width: 120px;
                width: 100%;
            }
        }
    }
}
.groupSku{
    th,td{
        white-space: nowrap;
        max-width: 78px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 0 78px;
    }
}