.team_home {
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .contrl {
        background: #FFFFFF;
        justify-content: space-between;
        .c_left {
            padding: 25px 20px;
            padding-right: 115px;
            //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
            background: #f8fafd;
            opacity: 1;
            border-radius: 4px;
            //height: 80px
            box-sizing: border-box;
            position: relative;
            width: 960px; } }
    .home_page {
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%); }
    .releaseBtn {
        cursor: pointer; }
    .c_month {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #366FBB;
        cursor: pointer;
        text-align: center;
        line-height: 18px; }
    .progress {
        position: absolute;
        right: 20px;
        margin-left: 76px;
        li {
            font-size: 12px;
            margin-bottom: 5px; }
        .speed {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 10px; }
        .running {
            background: #FFBBAE; }
        .soon {
            background: #8EBFEE; }
        .over {
            background: #C2DBBB;
            color: #fff; } } }
.open_menu {
    margin: 20px 0 10px 5px;
    li {
        width: 140px;
        height: 120px;
        background: #366FBB;
        color: #fff;
        margin-right: 20px;
        padding: 20px 16px;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        .title {
            text-align: center;
            font-size: 16px;
            margin-bottom: 20px; } }
    li:last-child {
        margin-right: 0; } }
.Modeltitle {
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    font-size: 20px;
    color: #666666;
    padding: 12px 0 22px 0;
    position: relative;
    .closeModal {
        float: right;
        margin-top: 5px;
        cursor: pointer; } }

.team_home {
    .ant-modal-body {
        padding: 10px 0 !important; } }



.team_list {
    //display: flex
    //align-items: flex-start
    overflow: hidden;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .team_name {
        width: 160px;
        height: 800px;
        overflow: hidden;
        overflow-y: auto; }
    .team_home {
        width: 1240px;
        height: 800px; }
    .empty {
        flex: 0 0 160px;
        width: 160px;
        height: 152px;
        box-sizing: border-box;
        //border: 1px solid #ccc
        background: url('../../assets/book.png') no-repeat;
        background-size: 32px;
        background-position: center; }
    .qd_list {
        height: 600px;
        overflow: hidden;
        text-align: center;
        //overflow-y: auto
        li {
            box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
            border-radius: 4px;
            text-align: center;
            font-size: 16px;
            color: #366FBB;
            width: 158px;
            margin: 10px auto;
            padding-top: 20px;
            box-sizing: border-box; } } }

.team_list::-webkit-scrollbar {
    width: 8px; }

.team_list::-webkit-scrollbar-thumb {
    background-color: #366FBB;
    border-radius: 0px; }

.team_list::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 0px; }
.pagination {
    position: sticky;
    bottom: 0;
    padding: 10px;
    text-align: center;
    background: #fff;
    z-index: 10; }
