.home_caledar {
    width: 798px;
    height: 80px;
    display: flex;
    position: relative;
    overflow: hidden;
    border: 1px solid #8EBFEE;
    border-radius: 4px;
    li {
        .line {
            margin-right: 5px;
            width: 80px;
            height: 30px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 12px;
            line-height: 30px;
            color: #fff;
            background: #5E8BC8;
            text-align: center; }
        .line.active {
            background: #366FBB; }
        .line:hover {
            background: #366FBB; } } }
.home_channel {
    height: 600px;
    overflow: hidden;
    overflow-y: auto;
    .nodata {
        text-align: center;
        font-size: 12px;
        color: #cccccc;
        position: absolute;
        margin-top: 260px;
        left: 0;
        z-index: 9;
        width: 1200px;

        p {
            margin-top: 20px; } }
    .channelList {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        opacity: 1;
        border-radius: 4px;
        margin: 10px 0;
        padding: 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        color: #fff;
        .transform {
            >div {
                line-height: 20px;
                margin: 10px 0;
                //text-overflow: ellipsis
                justify-content: space-between !important;
                //overflow: hidden
                >p {
                    font-size: 12px;
                    flex: 1;
                    //overflow: hidden
 } } }                    //text-overflow: ellipsis
        .transform.small {
            transform: scale(0.8); } }
    .channelList.status1 {
        background: #FFBBAE; }
    .channelList.status0 {
        background: #8EBFEE; }
    .channelList.status2 {
        background: #C2DBBB;
        color: #FFF; } }

.home_ul {
    white-space: nowrap;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    position: relative;

    text-align: center;
    font-size: 12px;
    color: #366FBB;
    .loop_date {
        padding: 10px 0; }
    .lineLi {
        float: left;
        height: 80px;
        width: 32px;
        text-align: center;
        box-sizing: border-box;
        font-size: 12px;
        padding: 5px 0;
        border-radius: 4px;
        position: relative;
        color: #366fbb; }
    .lineLi.active {
        //background: #366fbb
 }        //color: #fff
    .lineLi.normal {
        color: #366fbb; } }
.home_ul.noflex {
    display: block;
    width: 238px; }
.home_ul.noflex::after {
    content: '';
    position: absolute;
    right: 0;
    height: 70%;
    width: 1px;
    background: #F0F0F0;
    top: 50%;
    transform: translateY(-50%); }
.lineLiBtn {
    flex: 0 0 30px;
    width: 30px;
    float: left;
    height: 30px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    //position: absolute
    left: 0;
    top: 0;
    background: #fff;
    //padding: 5px 0
    z-index: 9;
    font-size: 30px;
    background: #366FBB;
    color: #fff;
    text-align: center;
    line-height: 29px;
    border-radius: 50%; }

.lineLiBtn.prev {
 }    //border-right: 1px solid #8EBFEE

.lineLiBtn.next {
    right: 0;
    left: inherit;
 }    //border-left: 1px solid #8EBFEE

// .monthNum
//     position: absolute
//     left: 0
//     top: 0

