.MapBox {
    width: 1240px;
    height: 100%;
    background: rgba(156, 194, 197, 1);
    position: relative; }

.RouteList {
    width: 1240px;
    height: 360px;
    position: absolute;
    bottom: 0;
    .listBox {
        width: 1200px;
        height: 360px;
        margin: 0 auto;
        background: #fff;
        justify-content: space-between;
        box-sizing: border-box;
        border-radius: 20px 20px 0 0;
        padding: 20px;
        align-items: start;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        .listLeft,.listRight {
            width: 560px;
            //box-shadow: 0 3px 6px rgba(0,0 ,0 , 0.2)
            padding: 5px;
            border-radius: 4px 4px 0 0;
            .contrl_top {
                justify-content: space-between;
                .title {
                    font-size: 14px;
                    font-weight: bold;
                    color: #666666; } }
            .table_data {
                margin-top: 10px; }
            .page_change {
                float: right;
                padding-top: 7px; }
            .contrl_btn {
                li {
                    width: 60px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    font-size: 12px;
                    color: #fff;
                    border-radius: 4px;
                    background: #366FBB;
                    margin-left: 10px;
                    cursor: pointer; } } } }
    .hideOrshow {
        cursor: pointer;
        color: #cccccc;
        font-size: 12px; } }
.RouteList.bottom {
    bottom: -295px; }

.itinerary {
    height: 842px;
    overflow: hidden;
    //overflow-y: auto
    position: relative; }

.identifier {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    margin-left: 15px;
    font-size: 14px;
    font-weight: bold; }

.contrlRules {
    li {
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-right: 10px; }
    img {
        width: 16px;
        max-height: 16px; }
    li:last-child {
        font-size: 12px;
        color: #366FBB;
        margin-right: 0;
        width: auto; } }

.AddNewWay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0,0 ,0 ,0.5 );
    .hide {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0; }
    .content {
        position: absolute;
        width: 500px;
        height: 380px;
        background: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 4px;
        .title {
            font-size: 16px;
            text-align: center;
            padding: 14px 0;
            background: #5E8BC8;
            color: #ffffff; }
        .line {
            margin-bottom: 25px;
            .lineTitle {
                width: 100px;
                text-align: right;
                margin-right: 10px; }
            input {
                width: 320px;
                height: 30px; } } } }
.contrlLine {
    margin-top: 75px;
    justify-content: center;
    li {
        width: 78px;
        height: 30px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        line-height: 30px;
        margin-right: 40px;
        background: #9AB6DD;
        border-radius: 4px;
        cursor: pointer; }
    li:last-child {
        background: #366FBB;
        margin-right: 0; } }

.editorMap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
    .editorLine {
        text-align: center;
        justify-content: center;
        margin-top: 20px;
        .title {
            font-size: 20px;
            color: #fff;
            font-weight: bold; } }
    .contrlLine {
        margin-top: 0;
        margin-left: 50px;
        li {
            margin: 0;
            margin-left: 10px; } }
    .mapEditorPng {
        display: block;
        margin: 10px auto 0; }
    .editorTips {
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin-top: 5px; } }

.search_and_info {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    padding: 10px;
    input {}
    .search_btn {
        width: 80px;
        height: 30px;
        border-radius: 0 4px 4px 0;
        background: #366FBB;
        line-height: 30px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        cursor: pointer; }
    .routeName {
        margin-bottom: 10px;
        padding: 6px 10px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3); } }


.mapPoint {
    border: 0;
    width: 80px;
    .mapPoint_img {
        border-radius: 50%;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 2; }
    .circular {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        background: #fff;
        margin: 0 auto; }
    .triangle {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 16px solid red;
        margin: 2px auto; }
    .limit {
        height: 80px;
        position: relative;
        border-radius: 50%;
        background: #fff;
        .info {
            max-width: 250px;
            position: absolute;
            left: 0;
            top: 0;
            background: #fff;
            height: 80px;
            padding: 10px 18px;
            padding-left: 80px;
            border-radius: 50px 4px 4px 50px;
            z-index: 1;
            line-height: 30px;
            overflow: hidden;
            padding-left: 0;
            padding-right: 0;
            width: 0;
            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
            .title {
                font-size: 14px;
                font-weight: bold; } } } }
.mapPoint:hover {
    .info {
        //transition:  all 1s
        width: auto;
        padding-left: 80px;
        padding-right: 18px;
        display: block; } }

.BMapLabel {
    border: 0 !important;
    background-color: transparent !important; }


.mask {
    //display: none
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0 ,0 ,0.5 );
    z-index: 10;
    .hide {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0; }
    .content {
        width: 320px;
        height: 210px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: #fff;
        border-radius: 4px;
        .title {
            font-size: 16px;
            padding: 20px 0;
            text-align: center;
            border-bottom: 1px solid #E5E5E5; }
        .ctx {
            width: 192px;
            margin: 25px auto;
            font-size: 12px;
            text-align: center;
            p {
                line-height: 25px; } }
        .contrlLine {
            margin-top: 0; } } }
