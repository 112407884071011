.groupList {
  //height: 100%
  //overflow: hidden
  //overflow-y: auto
  position: relative;
  .group_list_table_tr {} }
.goods_list_show {
  //height: auto
  .ant-table-thead {
    th {
 } } }      //white-space: nowrap !important
.search {
      width: 100%;
      margin-top: 20px;
      .needMargin {
                margin-bottom: 20px;
                margin-right: 20px; }
      .search-left {
                //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
                background: #f6f6f6;
                padding: 20px;
                border-radius: 20px;
                font-size: 12px;
                .input-radius {
                    border-radius: 4px;
                    width: 160px;
                    height: 30px; }
                .clear {
                   cursor: pointer;
                   font-size: 12px;
                   font-weight: 400;
                   line-height: 21px;
                   color: #999999;
                   opacity: 1;
                   margin-right: 5px;
                   margin-left: 10px; }
                .search-btn {
                   width: 80px !important;
                   height: 30px !important;
                   background: #366FBB !important;
                   opacity: 1 !important;
                   border-radius: 4px !important;
                   font-size: 12px; } }
      .issue {
            margin-left: 20px;
            height: 120px;
            background: #366FBB;
            opacity: 1;
            border-radius: 4px;
            box-sizing: border-box;
            width: 220px;
            i {
                width: 50px;
                height: 50px;
                line-height: 50px;
                display: inline-block;
                vertical-align: middle;
                background: url("../../../assets/click.png") no-repeat;
                background-size: 43px; } } }

.grouptype {
  width: 140px;
  overflow: hidden;
  height: 120px;
  background: #366FBB;
  opacity: 1;
  border-radius: 20px;
  color: #fff;
  vertical-align: middle;
  margin: 0 5px;
  box-sizing: border-box;
  h2 {
    color: #fff; }
  .span-wrap {
    overflow: hidden !important;
    white-space: pre-wrap !important;
    word-break: break-all !important; } }

.team_contrl {
  p {
    padding: 5px 0; }
  p:nth-child(odd) {} }

.open_menu {}

.inline {
  display: inline-block; }

.testTable {
  width: 440px !important;
  box-sizing: border-box;
  overflow: hidden;
  tr {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #e2e2e2; }
  tr:first-child,tr:last-child {
    border-bottom: 0; }

  td {
    display: inline-block;
    width: 72px;
    padding: 5px 0;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; } }

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset !important; }

.refund_btn {
  cursor: pointer;
  color: #366FBB; }

.Modeltitle.refund {
  font-size: 16px;
  padding: 4px 0 14px 0;
  color: #666; }

.refundContrl {
  justify-content: center;
  margin-top: 15px;
  button {
    width: 78px;
    height: 30px;
    margin-right: 40px;
    background: #9AB6DD;
    color: #fff;
    border: 0;
    border-radius: 4px; }
  button:last-child {
    margin: 0;
    background: #366FBB; } }

.group_type {
  font-size: 12px;
  padding: 3px;
  color: #16BC67;
  padding: 1px 10px;
  border-radius: 4px;
  background: #E7F8EF;
  margin-top: 5px; }


.group_type.gonghuo {
  color: #FF9000;
  background: #FFF4E5; }









