@font-face {
    font-family: "pingfang";

    src: url("./assets/pingfang.ttf");
}

* {
    font-family: "pingfang";
    -webkit-overflow-scrolling: touch;
}


body {
    margin: 0;
    font-family: "pingfang", -apple-system, BlinkMacSystemFont, "pingfang", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "pingfang", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
