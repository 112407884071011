p {
    margin: 0; }
.flex {
    display: flex;
    align-items: center; }
.logisticsRoute {
    margin: 0 20px; }
.citySelect {
    margin: 20px 0;
    font-size: 12px;
    text-align: left;
    color: #666666;
    input {
        width: 370px;
        box-sizing: border-box;
        font-size: 12px; }
    label {
        min-width: 95px;
        white-space: nowrap; } }
.add_address {
        color: 16px;
        width: 120px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
        background: #366FBB;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-btn {
            color: #ffffff; } }
.headLine {
        width: 370px;
        height: 10px;
        background: #5E8BC8;
        border-radius: 40px; }
.button-bac.cancel {
    background: #5e8bc8 !important; }
