.upshop {
    width: 100%;
    border: 1px solid #ccc;
    height: 300px; }

.contrlShop {
    .contrlBtn {
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        .contrlIcon {
            width: 20px;
            height: 20px;
            background: #ccc; } } }
