.detail {
   display: flex;
   .search {
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      position: relative;
      .jiaonang {
         border-radius: 4px; }
      .search_btn {
         text-align: center;
         width: 40px;
         position: relative;
         cursor: pointer; }
      .search_btn::after {
         position: absolute;
         left: 0;
         top: 0;
         height: 100%;
         content: '';
         width: 1px;
         background-color: #D9D9D9; }
      input {
         border-radius: 0;
         border: 0;
         background: none;
         height: 30px; } }

   .detail-flex {
      display: inline-block;
      flex-direction: column;
      height: 780px;
      flex: 1;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto; }
   .sale {
      width: 110px;
      box-sizing: border-box;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 4px;
      text-align: center;
      margin: 5px;
      .num {
         font-size: 16px;
         color: #366FBB;
         padding: 20px;
         display: inline-block; }
      h3 {
         margin: 0;
         padding: 20px 0;
         font-size: 16px;
         position: relative; }
      h3::after {
         position: absolute;
         left: 5px;
         right: 5px;
         height: 1px;
         background: #E5E5E5;
         content: '';
         bottom: 0; } }

   .goodslist {
      box-sizing: border-box;
      //height: 60px
      width: 350px;
      justify-content: space-around;
      align-items: center;
      //line-height: 60px
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 4px;
      margin-top: 10px;
      padding: 10px;
      .goodsname {
         display: inline-block;
         font-size: 16px;
         //font-family: Microsoft YaHei
         font-weight: 400;
         line-height: 21px;
         color: #666666;
         opacity: 1;
         text-overflow: ellipsis;
         max-width: 100px;
         white-space: nowrap;
         overflow: hidden; }

      .goodsprice {
         font-size: 16px;
         //font-family: Microsoft YaHei
         font-weight: 400;
         line-height: 21px;
         color: #366FBB;
         opacity: 1;
         text-overflow: ellipsis;
         max-width: 100px;
         white-space: nowrap;
         overflow: hidden; }
      .goodsnum {
         font-size: 12px;
         //font-family: Microsoft YaHei
         font-weight: 400;
         line-height: 16px;
         color: #666666;
         opacity: 1; }
      .adjust {
         font-size: 12px;
         //font-family: Microsoft YaHei
         font-weight: 400;
         line-height: 16px;
         color: #366FBB;
         opacity: 1;
         cursor: pointer; }
      .changeNumInput {
         border: none;
         border-bottom: 1px solid #d9d9d9;
         //width: 50px
         padding: 4px; }
      .ant-input-number-input {
         padding: 4px; } }

   .detail-left {
      width: 350px;
      box-sizing: border-box;
      padding: 20px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 4px;
      margin: 5px;
      max-height: 615px;
      overflow: hidden;
      overflow-y: auto;
      font-size: 12px;
      .title {
         font-size: 16px;
         font-weight: 400;
         line-height: 28px;
         color: #606060;
         opacity: 1;
         letter-spacing: 1px;
         overflow: hidden;
         text-overflow: ellipsis; }
      .over-active {
         font-size: 12px;
         font-weight: 400;
         line-height: 17px;
         color: #999999;
         opacity: 1; }
      .delivery {
         background: #D6E2F1;
         border-radius: 4px;
         color: #6B6B6B;
         height: 24px;
         padding: 4px 20px;
         margin-left: 10px; }
      .explanation {
         font-size: 12px;
         font-weight: 400;
         line-height: 22px;
         color: #606060;
         opacity: 1;
         margin: 10px 0; }
      .photoList {
         overflow: hidden;
         overflow-x: auto;
         white-space: nowrap; }
      .photoList::-webkit-scrollbar {
         height: 5px;
         width: 3px; }

      img {
         width: 120px;
         height: 120px;
         opacity: 1;
         border-radius: 4px;
         margin: 0 10px 4px 0;
         display: inline-block; } }
   .top {
      padding: 20px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 4px;
      background: #F8FAFD;
      margin-bottom: 20px;
      .ant-input-group-wrapper {
         vertical-align: middle;
         input {
            border-radius: 30px; } } }
   .line {
      display: inline-block;
      width: 12px;
      height: 42px;
      position: absolute;
      top: -25px;
      background: url('../../../assets/line.png') no-repeat; }
   .line-right {
      right: 10px; }
   .line-left {
      left: 10px; }
   .col-5 {
      width: 100px;
      height: 80px;
      background: #366FBB;
      opacity: 0.8;
      color: #fff;
      line-height: 80px;
      font-size: 16px;
      font-weight: 800;
      text-align: center;
      border-radius: 10px 0px 0px 10px; }
   .col-18 {
      padding-left: 10px;
      border-radius: 0px 10px 10px 0px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      .arrivetime {
         font-size: 12px;
         //font-family: Microsoft YaHei
         font-weight: 400;
         line-height: 30px;
         color: #999999;
         opacity: 1;
         p {
            margin: 0;
            padding: 0;
            .present {
               font-size: 12px;
               //font-family: Microsoft YaHei
               font-weight: 700;
               line-height: 16px;
               color: #000000;
               opacity: 1; }
            .presentnum {
               font-size: 12px;
               //font-family: Microsoft YaHei
               font-weight: 700;
               line-height: 16px;
               color: #366FBB;
               opacity: 1; }
            .col-4 {
               color: #366FBB;
               line-height: 80px; } } } }
   .detail-center-top {
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 10px;
      padding: 0 10px;
      .username {
         font-size: 16px;
         //font-family: Microsoft YaHei
         font-weight: 700;
         line-height: 21px;
         color: #000000;
         opacity: 1;
         margin: 0 5px; }
      .starttime {
         display: block;
         font-size: 12px;
         //font-family: Microsoft YaHei
         font-weight: 700;
         color: #000000;
         line-height: 25px;
         opacity: 1; }
      .detail-center-tit {
         background: #FFFFFF;
         box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
         opacity: 1;
         border-radius: 10px; }
      .saleroom {
         display: inline-block;
         width: 80px !important;
         height: 60px !important;
         line-height: 60px;
         background: #FFFFFF !important;
         box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
         opacity: 1 !important; }
      .borderL {
         background: #366FBB !important;
         text-align: center;
         font-size: 16px;
         color: #fff; }
      .borderR {
         text-align: center;
         font-size: 20px !important;
         font-weight: 400 !important; } } }
.deliveryTime {
   font-size: 12px;
   color: #606060;
   align-items: flex-start !important;
   margin: 10px 0;
   .table {
      flex: 1;
      table {
         width: 100%;
         border-color: #ccc;
         color: #333;
         margin-bottom: 10px;
         text-align: center; } }
   .pstime {
      flex-wrap: wrap;
      li {
         padding: 4px 11px;
         color: #366FBB;
         border: 1px solid #366FBB;
         border-radius: 4px;
         margin-right: 10px;
         margin-bottom: 10px; } }
   .cansee {
      li {
         margin: 6px;
         margin-left: 0; }
      img {
         width: 16px;
         height: 16px;
         margin: 0; } }
   .detail_title {
      flex: 0 0 60px; } }
.output {
   padding: 5px 0;
   box-sizing: border-box;
   margin: 10px 0;
   .bigImg {
      img {
         width: 100%;
         height: auto;
         box-sizing: border-box;
         padding: 5px; }
      .ant-image {
         width: 100%;
         overflow: hidden; } }
   .smallImg {
      img {
         width: 100%;
         height: auto;
         margin: 0;
         box-sizing: border-box;
         padding: 5px; }
      .ant-image {
         width: calc(100%/3);
         overflow: hidden; } } }
.canseearea {
   flex-wrap: wrap;
   li {
      padding: 1px 6px;
      border: 1px solid #366FBB;
      border-radius: 4px;
      font-size: 12px;
      margin-right: 5px;
      margin-bottom: 5px; } }
