.searchList {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin: 3px;
    padding: 0 14px;
    .top {
        box-shadow: unset;
        border-bottom: 1px solid #E5E5E5;
        border-radius: 0;
        padding: 20px 0;
        background: #ffffff; }

    .searchAll {
        font-size: 12px;
        padding-left: 16px;
        color: #366FBB;
        cursor: pointer; } }
.shopListTitle {
    display: inline-block;
    background: #366FBB;
    color: #fff;
    font-size: 12px;
    padding: 7px 16px;
    margin: 20px 0 10px 0;
    border-radius: 4px; }

.detail {
    .search {
        overflow: unset;
        z-index: 99;
        position: relative;
        .searchRes {
            position: absolute;
            width: 158px;
            top: 35px;
            height: 140px;
            overflow: hidden;
            overflow-y: auto;
            background: #ffffff;
            z-index: 9;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            li {
                border-bottom: 1px solid #F0F0F0;
                padding: 5px;
                background: #fff;
                cursor: pointer; }
            li:hover {
                background: #366FBB;
                color: #fff; } } } }
.invalidDate {
    color: #d1cccc;
    cursor: not-allowed; }
.validDate {
    color: #366FBB; }
.validDate.active {
    background: #366FBB;
    color: #fff; }
.ctrlbtn {
    z-index: 9;
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    line-height: 24px; }

