.printPro {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 9;
    .printCotent {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 500px;
        max-height: 780px;
        border-radius: 4px;
        background: #fff;
        overflow: hidden;
        box-sizing: border-box;
        overflow-y: auto;
        .title {
            padding: 14px;
            text-align: center;
            font-size: 16px;
            color: #fff;
            background: #366FBB; }
        .case {
            text-align: center;
            padding: 20px 0;
            font-size: 12px; }
        .printCtx {
            margin: 0 20px;
            .checkBox {
                .select {
                    align-items: flex-start;
                    margin-top: 20px;
                    .selectTitle {
                        flex: 0 0 60px; }
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        white-space: nowrap;
                        line-height: 25px;
                        li {
                            min-width: 88px; } } } } }
        .info {
            background: #EAF0F8;
            font-size: 12px;
            border-radius: 4px;
            padding: 10px;
            padding-bottom: 0;
            p {
                margin-bottom: 10px; }
            .team_name {
                flex-wrap: wrap; } }
        .detail {
            display: flex;
            flex-wrap: wrap;
            p {
                width: 50%; } } }
    .contrlBtn {
        position: sticky;
        bottom: 0;
        padding: 10px 0;
        left: 0;
        width: 100%;
        margin-bottom: 0;
        text-align: center;
        justify-content: center;
        button {
            margin-right: 100px;
            width: 78px;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            background: #9AB6DD; }
        button:last-child {
            margin: 0;
            background: #366FBB; } } }
