.commanderList {
   height: 100%;
   overflow: hidden;
   overflow-y: auto;
   position: relative; }
.search-title {
   input {
      font-size: 12px; }
   font-size: 12px;
   margin: 20px 0;
   height: 120px;
   //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
   opacity: 1;
   border-radius: 4px;
   padding: 20px;
   background: #f8fafd;
   .search-btn {
      width: 80px !important;
      height: 30px !important;
      color: #fff;
      background: #366FBB !important;
      opacity: 1 !important;
      border-radius: 4px !important;
      margin: 20px;
      margin-left: 0;
      font-size: 12px; }
   .clear {
      cursor: pointer;
      margin-right: 5px;
      color: #909090; }
   .search-inp {
      width: 240px;
      border-radius: 4px; } }
.datalist {
   //height: 600px
   overflow: hidden;
   overflow-y: auto;
   .datalist_son {
      box-sizing: border-box;
      height: 100px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 4px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
   li {
      padding: 0 50px 0 10px;
      flex: 1;
      display: inline-block;
      vertical-align: middle;
      .con-name {
         font-size: 12px;
         //font-family: Microsoft YaHei
         font-weight: 400;
         line-height: 26px;
         color: #000000;
         opacity: 1; }
      .con-tit {
         width: 112px;
         height: 21px;
         font-size: 12px;
         //font-family: Microsoft YaHei
         font-weight: 400;
         line-height: 21px;
         color: #999999;
         opacity: 1;
         margin: 0 20px; }
      .sale {
         width: 143px;
         height: 21px;
         font-size: 12px;
         //font-family: Microsoft YaHei
         font-weight: 400;
         line-height: 21px;
         color: #666666;
         opacity: 1;
         margin-right: 10px;
         display: inline-flex;
         align-items: center;
         flex-wrap: nowrap; }
      .con-group {
         width: 251px;
         height: 21px;
         font-size: 12px;
         //font-family: Microsoft YaHei
         font-weight: 400;
         line-height: 21px;
         color: #CCCCCC;
         opacity: 1; }
      .list-num {
         width: 30px;
         height: 30px;
         background: #366FBB;
         border-radius: 50%;
         opacity: 0.8;
         text-align: center;
         line-height: 30px;
         color: #fff;
         font-size: 16px;
         font-weight: 400;
         margin: 0 10px; } } }
.flex {
   display: flex;
   align-items: center; }

.commanderList {
   .nodata {
      .center {
         text-align: center;
         padding: 130px 0;
         p {
            margin-top: 10px;
            color: #828080; } } } }
.export_merchant {
  margin-left: 10px; }

