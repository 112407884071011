.allprint {
    // position: absolute
    // left: 0
    // right: 0
    // top: 0
    // bottom: 0
    overflow: hidden;
    background: #F2F2F2;
    height: 100%;
    overflow-x: auto;
    .poll {
        width: 1400px;
        margin: 0 auto;
        box-sizing: border-box;
        border-style: solid;
        border-width: 15px;
        border-color: #fff;
        align-items: flex-start;
        height: 100%;
        overflow: visible;
        position: relative;
        .left,.right {
            box-sizing: border-box;
            padding: 15px 20px;
            overflow: hidden; }
        .left {
            flex: 0 0 810px;
            float: left;
            //border-right: 20px solid #fff
            .content {
                padding: 10px 20px;
                background: #fff;
                overflow-y: hidden; } }
        .right {
            flex: 0 0 530px;
            float: right; }
        .right.none {
            display: none; }
        .center {
            width: 15px;
            height: 100%;
            background: #fff; }
        .tableHeader {
            justify-content: space-between; } }
    .printBtn {
        display: inline-block;
        color: #fff;
        background: #027DB4;
        padding: 3px 15px;
        font-size: 14px;
        border-radius: 2px;
        cursor: pointer; }
    .set_title {
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 15px; }
    .receipt_title {
        margin-right: 10px;
        min-width: 65px;
        text-align: right; }
    .receipt_line {
        margin-bottom: 20px;
 }        //align-items: flex-start
    .print_btn {
        display: inline-block;
        color: #fff;
        background: #1988CB;
        border-radius: 3px;
        padding: 2px 10px;
        cursor: pointer; } }
