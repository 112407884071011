p {
    margin: 0;
    line-height: initial; }
ul {
    margin: 0; }
.flex {
    display: flex;
    align-items: center; }
.datum {
    margin: 0 20px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .box {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        padding: 20px;
        margin: 20px;
        border-radius: 10px; }

    .orderLi {
        font-size: 16px;
        line-height: initial;
        margin-left: 20px;
        li {
            border-radius: 20px;
            padding: 9px 20px;
            background: #5E8BC8;
            color: #ffffff;
            margin-right: 10px;
            cursor: pointer; }
        li.active {
            background: #366FBB; } }

    .gettime {
        color: #666666;
        font-size: 20px;
        font-weight: Bold; }
    .echarts {
        padding: 0 40px;
        margin-top: 30px;
        .echarts_son {
            height: 300px; }
        .chartTitle {
            font-size: 32px;
            color: #366FBB;
            .data_title {
                color: #575757;
                font-size: 20px;
                margin-bottom: 10px; }
            .data_num {
                font-weight: 600; } } }
    .trade {
        .title {
            font-weight: bold;
            color: #575757;
            font-size: 20px;
            padding-bottom: 20px;
            border-bottom: 2px solid #DFDFDF; }
        .wealth {
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 30px; }
        li {
            padding: 0 10px;
            box-sizing: border-box;
            text-align: center;
            width: calc(23%);
            max-width: 160px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 20px;
            div {
                color: #366FBB;
                font-size: 20px;
                padding: 16px 0;
                font-weight: bolder; }
            div:first-child {
                font-size: 16px;
                color: #606060;
                border-bottom: 1px solid #E5E5E5; } } }
    .topSell {
        .title {
            font-weight: bold;
            color: #575757;
            font-size: 20px;
            padding-bottom: 20px; } } }
