.discountCoupon {
    margin: 20px;
    font-size: 12px;
    .box {
        //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
        background: #f8fafd;
        padding: 20px;
        border-radius: 4px;
        font-size: 12px; }
    .flex {
        display: flex;
        align-items: center; }
    p {
        margin: 0; }
    .writeSearch {
        font-size: 12px;
        //font-family: Microsoft YaHei
        font-weight: 400;
        line-height: 21px;
        color: #8C8C8C;
        opacity: 1; }
    .explain {
        margin: 20px 0;
        .tips {
            padding: 10px 12px;
            background: #EAF0F8;
            border-radius: 4px;
            font-size: 12px;
            //font-family: Microsoft YaHei
            font-weight: 400;
            line-height: 21px;
            color: #8C8C8C;
            opacity: 1; }
        .addDiscount {
            border: 1px dashed #366FBB;
            margin-top: 18px;
            border-radius: 4px;
            padding: 20px;
            justify-content: space-between;
            .discountTxt {
                font-size: 12px;
                //font-family: Microsoft YaHei
                font-weight: 400;
                line-height: 16px;
                color: #8C8C8C;
                opacity: 1;
                margin-left: 20px;
                .title {
                    font-size: 16px; } } } }
    .searchBtn {
        border-radius: 4px;
        margin-left: 100px; }
    .tips {
        position: relative;
        left: unset; }
    .ruleTab {
        margin: 20px 0;
        border-radius: 4px;
        li {
            padding: 7px 10px;
            font-size: 12px; } } }
