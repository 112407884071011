@import "~antd/dist/antd.css";
@import "~@alifd/next/dist/next.css";

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0;
  /**/
}
/* #root{
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
} */
#root {
  height: 100%;
}
/* div::-webkit-scrollbar{
  width:0
} */
.ant-modal-header {
  background: #366fbb !important;
}
.need-margin {
  margin: 20px;
}
.ant-modal-title,
.ant-modal-close,
.ant-pagination-item-active a,
.ant-table-thead tr .ant-table-cell {
  color: #ffffff !important;
}

.ant-pagination-item-active,
.ant-table-thead tr .ant-table-cell {
  background: #366fbb !important;
}
.ant-select-selecto {
  border-radius: 20px !important;
}
.button-bac {
  width: 78px;
  height: 30px !important;
  font-size: 12px !important;
  background: #366fbb !important;
  opacity: 1 !important;
  border-radius: 4px !important;
  color: #ffffff !important;
}
.dark-row {
  background: #f6f6f6;
}
/* .ant-checkbox-inner{
  border-radius: 20px !important;
} */
.ant-table table {
  /* background: #366FBB; */
}

.search-left {
  border-radius: 4px !important;
}

body {
  /* background:"#FFFFFF", */
  /* min-width:1800px ; */
  /* overflow-y: auto; */
}

ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sku-creator-wrap {
  background: #ffffff;
  margin: 0px 100px;
  margin-bottom: 0;
  padding: 30px 30px;
}
.sku-select-wrap {
  width: 500px;
  background: #fff;
  padding: 50px 0;
  margin: 30px auto;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px !important;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-anchor-wrapper {
  background-color: transparent !important;
}
.font12 {
  font-size: 12px;
}
.font12 input {
  font-size: 12px;
}

.ant-table-thead {
  background: #366fbb;
}

/* ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
} */

::-webkit-scrollbar-thumb {
  background-color: rgb(79, 137, 175);
  border-radius: 6px;
}
::-webkit-scrollbar-track {
  background-color: #9fc7c5;
  border-radius: 6px;
}

/* html,body{
  position: fixed;
} */

@page {
  size: A4 portrait;
  margin: 0;
}
@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }
  html,
  body {
    min-width: 0;
    width: 210mm;
    height: 297mm;
    color: #000;
    background: #fff;
    overflow: hidden;
  }
  .print-hide {
    opacity: 0;
    display: none;
  }
}
/* .print-hide{
  display: none;
} */

.nodata p {
  color: #cccccc !important;
}

.page_style {
  /* margin-top: 50px; */
  position: sticky;
  right: 20px;
  bottom: 0;
  float: right;
}

.ant-layout-content {
  position: relative;
  /* height: 800px; */
}

/* body
    {background: #F0F0F0}

.app
    {display: flex;
    justify-content: space-between}

.container
    {height: calc(100vh - 144px);
    background: #fff;
    margin-right: 0;
    overflow: auto;
    flex: 5;
    padding: 20px}
.no-padding-container
    {padding: 0;
    background: #F0F0F0}
.ant-layout
    {width: 100%;
    display: flex;
    justify-content: center;
    align-items: center}
.lay_out_header
    {width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 80px} */

html,
body {
  overflow: hidden;
}
