.home_list {
    width: 100%;
    //border: 1px solid #366FBB
    padding: 0 20px;
    position: relative;
    min-height: 500px;
    .emptyLi {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        color: #366fbb;
        font-size: 16px; }
    .channel_list {
        align-items: flex-start;
        width: 100%;
        min-height: 68px;
        //border: 1px solid #ccc
        margin-bottom: 6px;
        //max-height: 500px
        //overflow: hidden
        //justify-content: space-between
        //overflow-y: auto
        .channel_name {
            text-align: center;
            box-sizing: border-box;
            padding: 10px;
            font-size: 16px;
            border-radius: 4px;
            width: 140px;
            //min-height: 80px
            border: 1px solid #8EBFEE;
            font-weight: bolder;
            color: #366FBB;
            p {
                font-weight: bolder;
                color: #366FBB;
                font-size: 16px; } } }
    .pagination {
        position: sticky;
        bottom: 0;
        padding: 10px;
        text-align: center;
        background: #fff;
        z-index: 10;
        text-align: right;
        padding-right: 100px; } }
