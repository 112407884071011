.register {
    width: 600px;
    background: #fff;
    z-index: 1;
    padding: 40px 50px;
    padding-top: 30px;
    box-sizing: border-box;
    position: fixed;
    left: 50%;
    top: 50%;
    max-height: 90vh;
    transform: translate(-50%,-50%);
    border-radius: 6px;
    overflow: hidden;
    overflow-y: auto;
    .ant-upload-picture-card-wrapper {
        margin-left: 0; }
    .title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        padding-bottom: 20px; }
    .fromTitle {
        padding-left: 10px;
        font-size: 16px;
        font-weight: bold;
        border-left: 3px solid #366FBB;
        margin-bottom: 30px; }
    .line {
        position: relative;
        font-size: 12px;
        padding-bottom: 20px;
        margin-bottom: 5px;
        label {
            width: 84px;
            text-align: right;
            margin-right: 20px;
            display: inline-block; }

        input {
            width: 300px;
            height: 30px;
            line-height: 30px;
            border-radius: 4px; }
        .identity {
            .identityPhoto {
                width: 150px;
                height: 90px;
                border: 1px dashed #7a7676;
                margin-right: 20px;
                line-height: 90px;
                text-align: center;
                cursor: pointer;
                position: relative;
                .ant-upload-list {
                    display: none; }
                img {
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    max-width: 100%;
                    max-height: 100%; }
                p {
                    margin-top: 35px; }
                .uploads {
                    z-index: 2;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: 0;
                    overflow: hidden;
                    button {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        opacity: 0; } } } } }
    .contrl {
        margin-top: 35px;
        justify-content: center;
        padding-bottom: 10px;
        .btn {
            padding: 5px 15px;
            border-radius: 4px;
            color: #fff;
            font-size: 14px;
            background: #d9534f;
            cursor: pointer;
            margin-right: 50px; }
        .btn.com {
            background: #204d74;
            margin: 0; } } }
