.standardgoods {
  //height: 100%
  overflow: hidden;
  //overflow-y: auto
  position: relative;
  margin: 0 0 0 20px; }
.search {
      width: 100%;
      margin-top: 20px;
      .needMargin {
        margin-bottom: 20px; }
      .search-left {
        width: 960px;
        //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
        background: #f8fafd !important;
        padding: 20px;
        border-radius: 20px;
        box-sizing: border-box;
        .input-radius {
                border-radius: 20px;
                width: 200px; }
        .clear {
            cursor: pointer;
            width: 128px;
            height: 21px;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            color: #999999;
            opacity: 1;
            margin-right: 5px; } }
      .issue {
            margin-left: 20px;
            height: 140px;
            background: #366FBB;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            opacity: 1;
            border-radius: 10px;
            box-sizing: border-box;

            i {
                width: 65px;
                height: 60px;
                line-height: 60px;
                display: inline-block;
                vertical-align: middle;
                background: url("../../../assets/click.png") no-repeat; }
            span {
                height: 62px;
                font-size: 24px;
                vertical-align: middle;
                //font-family: Microsoft YaHei
                font-weight: bold;
                line-height: 60px;
                color: #FFFFFF;
                opacity: 1;
                word-wrap: break-word; } } }
.table-data {
        margin-top: 20px;
        .btn {
            font-size: 12px;
            background: transparent;
            color: #909090;
            opacity: 1;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            box-shadow: none; }
        .btn:hover {
            color: #5E8BC8;
            border-color: #5E8BC8; } }
.updateList {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #366FBB;
    position: relative;
    text-align: center;
    line-height: 42px;
    cursor: pointer;
    margin-right: 10px; }

.delcontrl {
    li {
        padding: 5px 20px;
        font-size: 14px;
        color: #fff;
        background: #1890FF;
        cursor: pointer; }
    li.cancel {
        background: #FF4D4F;
        margin-right: 40px; } }

.minWidth80 {
    max-width: 80px;
    display: inline-block;
    margin-right: 7px; }

.ant-modal-body {
    padding: 10px 24px; }

.goods_list_show {
  margin-top: 20px;
  //height: 555px
  overflow: hidden;
  overflow-y: auto; }

.goods_list_show::-webkit-scrollbar {
  width: 3px; }

.goods_list_show::-webkit-scrollbar-thumb {
  background-color: rgb(79, 137, 175);
  border-radius: 6px; }

.goods_list_show::-webkit-scrollbar-track {
  background-color: #f1ebeb;
  border-radius: 3px; }

.page_style {
    text-align: center;
 }    //margin: 20px

.ant-table-tbody > tr > td {
  padding: 10px 2px;
  color: #909090 !important;
  height: 60px;
  box-sizing: border-box; }
















